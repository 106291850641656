<template>
  <div>
    <v-card elevation="2" tile>
      <v-card-title>Edición de Layout {{ layout.nombre }}</v-card-title>
      <v-divider></v-divider>
      <v-form v-model="valid">
        <v-container>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="layout.nombre"
                label="Nombre del Layout"
                :rules="rules.concat(v => (v && v.length <= 100) || 'Máximo 100 caracteres')"
                :counter="100"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="layout.descripcion"
                label="Descripción"
                :rules="rules.concat(v => (v && v.length <= 250) || 'Máximo 250 caracteres')"
                :counter="250"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="layout.tema_id"
                :items="temas"
                :item-text="'valor_texto'"
                :item-value="'id'"
                attach
                label="Tema"
                :rules="required"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="layout.tipo_carga_id"
                :items="tiposCarga"
                :item-text="'text'"
                :item-value="'value'"
                attach
                label="Tipo de Carga"
                :rules="required"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="layout.version"
                label="Versión"
                :rules="required.concat(v => (v && v.length <= 20) || 'Máximo 20 caracteres')"
                :counter="20"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="layout.delimitadorCampo"
                :items="delimitadoresCampo"
                :item-text="'text'"
                :item-value="'text'"
                attach
                label="Delimitador de Campo"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                v-model="layout.delimitadorTexto"
                :items="delimitadoresTexto"
                :item-text="'text'"
                :item-value="'text'"
                attach
                label="Delimitador de Texto"
              ></v-select>
            </v-col>
            <v-col cols="12" md="6">
              <v-select
                v-model="layout.formatoFecha"
                :items="formatosFecha"
                :item-text="'text'"
                :item-value="'text'"
                attach
                label="Formato Fecha"
              ></v-select>
            </v-col>
          </v-row>
          <v-row v-show="false">
            <v-col cols="12" md="6">
              <v-text-field
                v-model="layout.erroresPermitidos"
                label="% Errores Permitidos"
                :counter="2"
                type="number"
                hide-details="auto"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-switch
                v-model="layout.validarLlavePrimaria"
                :label="`Validación llave primaria`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch
                v-model="layout.permitirDuplicados"
                :label="`Permite registros duplicados`"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4">
              <v-switch v-model="layout.activo" :label="`Estado`"></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <CButton color="secondary" @click="preCancelar">Cancelar</CButton>&nbsp;
        <div v-if="loadingSave" class="spinner-border" role="status"></div>
        <CButton
          v-if="!loadingSave"
          color="primary"
          class="px-4"
          @click="save()"
          :disabled="!valid"
          >Guardar</CButton
        >
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialog" max-width="420">
      <v-card>
        <v-card-title class="text-h5">
          ¿Desea cancelar la edición?
        </v-card-title>

        <v-card-text>
          Si cancela la edición los datos diligenciados no quedarán guardados.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="goBack">
            Cancelar y volver
          </v-btn>

          <v-btn color="green darken-1" text @click="dialog = false">
            Continuar la edición
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LayoutService from "@/services/layout.service"
import ListaService from "@/services/lista.service"
import validationField from "@/util/validationField"
import { mapState } from "vuex"

export default {
  name: "Calidad",

  data() {
    return {
      layout: {
        nombre: undefined,
        descripcion: undefined,
        tema_id: undefined,
        tipo_carga_id: undefined,
        version: undefined,
        delimitadorCampo: undefined,
        delimitadorTexto: undefined,
        formatoFecha: undefined,
        erroresPermitidos: undefined,
        validarLlavePrimaria: undefined,
        permitirDuplicados: undefined,
        activo: undefined,
      },
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 2) || "Mínimo 2 caracteres",
      ],
      required: [
        (value) => !!value || "Requerido"
      ],
      temas: [],
      tiposCarga: [
        { value: 1, text: "Full" },
        { value: 2, text: "Incremental" },
      ],
      delimitadoresCampo: [
        { value: 1, text: "," },
        { value: 2, text: ";" },
        { value: 3, text: "|" },
      ],
      delimitadoresTexto: [
        { value: 1, text: "," },
        { value: 2, text: ";" },
        { value: 3, text: "|" },
      ],
      formatosFecha: [
        { value: 1, text: "DD/MM/YY" },
        { value: 2, text: "YYYY-MM-DD" },
      ],
      loading: false,
      loadingSave: false,
      message: "",
      dialog: false
    };
  },
  computed: {
    ...mapState(["permissions"]),
  },
  mounted() {
    if (!this.permissions.includes("Definición de layouts"))
      this.$router.push("/dashboard");

    if (localStorage.getItem("datos_layout")) {
      this.layout = JSON.parse(localStorage.getItem("datos_layout"));
    }

    this.fetchTemas('')
    // this.fetchTiposCarga('')
    // this.fetchDelimitadoresCampo('')
    // this.fetchDelimitadoresTexto('')
    // this.fetchFormatosFecha('')
    // this.fetchFormatosNumero('')
  },
  methods: {
    save() {
      this.loadingSave = true;
      console.log(this.layout);
      if (this.layout.hasOwnProperty("id")) {
        LayoutService.editItem(this.layout).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/layouts` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      } else {
        LayoutService.newItem(this.layout).then(
          (response) => {
            this.$router.push({ path: `/cargue-archivos/layouts` });
            console.log(response.data);
          },
          (error) => {
            this.content =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
          }
        );
      }
    },
    fetchTemas() {
      this.loading = true;
      ListaService.getListItems(1).then(
        (response) => {
          this.temas = response.data;
          console.log(response.data);
          this.loading = false;
        },
        (error) => {
          this.content =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          this.loading = false;
        }
      );
    },
    preCancelar() {
      this.dialog = true
    },
    goBack() {
      this.$router.push({ path: "/cargue-archivos/layouts" });
    },
  },
};
</script>

<style>
</style>
